.alert-box{
    position: absolute;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    background: #ffff;
    width: 500px;
    top: 15%;
    left: 50%;
    z-index: 99999;
    transform: translate(-50%, -50%);
}

.alert-box .alert-content {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
}

.alert-box .alert-content .alert-message{
    width: 100%;
}

.alert-box .alert-content .alert-message h3{
    font-size: 24px;
    font-weight: 900;
}

.alert-box .alert-btn {
    display: flex;
    width: 100%;
    justify-content: end;
}

.alert-box .alert-btn button {
    padding: 0.5rem 1rem;
    background: #19be71;
    font-size: 20px;
    color: white;
    border: none;

}