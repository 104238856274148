.product-card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 6px;
}

.itemblog-container {
  width: 100%;
  padding: 2rem;
  padding-top: 0rem;
  display: flex;
}

.box-item {
  width: 1140px;
  margin: 0 auto;
}

.product-item {
    
    display: flex;
    justify-content: center;
    align-items: start;

}

.product-item .product-content:hover {
  box-shadow: 0px 0px 10px rgba(1, 136, 57, 0.5);
}


.product-item .product-content {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 200px;
    height: 320px;
}

.product-item .product-content .product-img {
    display: flex;
    justify-content: center;
    align-items: start;
}

.product-item .product-content .product-img .imgs {
    padding: 1.5rem;
    width: 180px;
    height: 180px;
    overflow: hidden;
}

.product-item .product-content .product-img .imgs img{
    width: 100%;
}

.product-item .product-content .product-text {
    padding: 0 0.3rem;
    margin-top: 1rem;
}

.product-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 150px;
  }

.product-search button {
  padding: 1rem 2rem;
  background: #04AA6D;
  color: #fff;
  font-size: 20px;
  border: none;
  transition: all 0.7s ease;
}

.product-search button:hover {
  background: #037249;
}
  .product-search .product-search-wrapper .product-search-input {
    width: 200px;
    display: flex;
  }
  
  .product-search .product-search-wrapper .product-search-input label {
    width: 50px;
    font-size: 20px;
    font-weight: 600;
  }
  
  .product-search .product-search-wrapper {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .product-search .product-search-wrapper .product-search-input input {
    width: 100%;
    padding: 0 1rem;
    font-size: 20px;
    font-weight: 600;
    border: none;
    outline: none;
  }

  