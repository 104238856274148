.addStock-container {
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.addStock-container .addstock-table{
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}

.addStock-container .header {
    width: 100%;
    padding: 1rem 0rem;
    display: flex;
    justify-content: space-between;
}

.addStock-container .header h1 {
    font-weight: 600;
}

.addStock-container .header button {
    margin-right: 3rem;
    padding: 0.5rem 1rem;
    background: #0f82b3;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    border: none;
    transition: all 0.5s ease;

    &:hover{
        background: #0a5879;
    }
}

.addStock-container .addstock-table table {
    text-align: center;
}

.addStock-container .add-header{
    display: flex;
    justify-content: flex-end;
    align-content: center;
}

.addStock-container .add-header button {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    margin: 0rem 0.5rem;
    transition: all 0.5s ease;
    border: none;
}

.addStock-container .add-header #btn-1{
    background: #b01c1c;

    &:hover{
        background: #861616;
    }
}

.addStock-container .add-header #btn-2{
    background: #1cb066;

    &:hover{
        background: #126c40;
    }
}

.addStock-container .add-header #btn-3{
    background: #1c1cb0;

    &:hover{
        background: #163f86;
    }
}

.addStock-container .add-content{
    margin-top: 1rem;
    display: flex;
    width: 100%;
}
.addStock-container .add-content .box1{
    display: flex;
    height: 400px;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}

.addStock-container .add-content .box1 #input{
    width: 100%;
}

.addStock-container .add-content .box2{
    padding: 0.5rem;
    width: 50%;
}

.addStock-container .add-content .box2 .item-header{
    margin: 1rem;
    padding: 0rem 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.addStock-container .add-content .box2 .item-header h3{
    font-weight: 600;
}

.addStock-container .add-content .box2 .item-header button{
    padding: 0.5rem 2rem;
    font-weight: 600;
    font-size: 16px;
}

.addStock-container .add-content .box2 .item .item-add #input{
    width: 100%;
}
