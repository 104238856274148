@import url('https://fonts.googleapis.com/css2?family=Sarabun&display=swap');

.con-ani {
  transition: all 0.4s ease;
}

.pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-top: 2rem;
}

.pagination .page-num a{
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.pagination .page-next {
  cursor: pointer;
  padding: 0.5rem 1rem;
  background: #04AA6D;
  border-radius: 5px;
  color: #fff;
}

.pagination .page-num{
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.pagination .active {
  background: #04AA6D;
  border-radius: 5px;
  color: #fff;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Sarabun", sans-serif;
  font-weight: 400;
  font-style: normal;
}

body {
  outline: none;
}

.home {
  display: flex;
  align-items: center;
  height: 90vh;
  justify-content: center;
  font-size: 3rem;
}

.container {
  max-width: 1440px;
  margin: 0.5rem auto;
  margin-bottom: 1rem;
  
}

.blog-item {
  display: flex;
  justify-content: center;
  margin: 3rem 0;
}

.form-blog {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 0.3rem;
  
}


.wrapper {
  display: flex;
  align-items: center;
  width: 200px;
  background: #fff;
  padding: 13px;
  
}

.wrapper .input-data {
  display: flex;
  justify-content: center;
  height: 70px;
  width: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 10px;
  
  
}

.wrapper .input-data input{
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 2px solid silver;
  font-size: 17px;
  padding-left: 1rem;
  
}

.wrapper .input-data input:focus ~ label,
.wrapper .input-data input:valid ~ label{
  transform: translateY(-30px);
}

.wrapper .input-data label{
  position: absolute;
  bottom: 20px;
  left: 0;
  color: gray;
  pointer-events: none;
  transition: all 0.3s ease;
  padding-left: 1rem;
}

.wrapper .input-data .underline{
  position: absolute;
  bottom: 0px;
  height: 2px;
  width: 100%;
}

.wrapper .input-data .underline:before{
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: rgb(18, 199, 18);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.wrapper .input-data input:focus ~ .underline:before,
.wrapper .input-data input:valid ~ .underline:before{
  transform: scaleX(1);
}

.blog-btn {
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}


.btn-form-upload button {
  margin: 0 0.3rem;
  background: #2966ac;
  padding: 1rem;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  color: #fff;
  transition: all 0.3s ease;
  cursor: pointer;
}

.btn-form-cancel button {
  margin: 0 0.3rem;
  background: #ac2929;
  padding: 1rem;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  color: #fff;
  transition: all 0.3s ease;
  cursor: pointer;
}

.btn-form-preupload button {
  margin: 0 0.3rem;
  background: #29ac45;
  padding: 1rem;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  color: #fff;
  transition: all 0.3s ease;
  cursor: pointer;
}

.btn-form-upload button:hover {
  background: #1d4879;
}

.btn-form-preupload button:hover {
  background: #1d7730;
}

.btn-form-cancel button:hover {
  background: #912323;
}


.img-input {
  display: flex;
  justify-content: center;
  align-items: end;
}

.img-input .img-blog {
  width: 200px;
  border-radius: 5px;
}

.img-blog-content {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 1rem;
}

.img-blog-content .content-emty {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 13x;
  transition: all 0.6s ease;
  
}

.img-blog-content .content-emty:hover {
  background-color: #cccaca;
}

.img-blog-content .content-emty .icon{
  font-size: 60px;
}

.img-input .img-blog img {
  width: 100%;
  height: 100%;
}

.img-blog .btn-select {
  text-align: center;
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  border-radius: 10px;
  background: #3ba72d;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  border: none;
  cursor: pointer;
  transition: all .3s ease;
}

.img-blog .btn-select:hover {
  background: #3ba72d ;
}

.dropdown {
  position: relative;
  width: 200px;
  padding: 1rem;
  padding-right: 1rem;
  color: gray;
}

.dropdown-input {
  padding: 0.3rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 997;
}

.dropdown-input input {
  height: 40px;
  width: 100%;
  border: none;
  outline: none;
  border: 2px solid silver;
  border-radius: 5px;
  font-size: 16px;
  padding-left: 0.4rem;
  padding-right: 2rem;
  color: gray;

}

.dropdown-input .sea-icon{
  position: absolute;
  right: 0.7rem;
  top: 25%;
  font-size: 26px;
}

.dropdown .dropdown-blog {
  position: absolute;
  height: 200px;
  width: 200px;
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 1s ease;
}

.dropdown .dropdown-blog .dropdown-input {
  position: absolute;
}

.dropdown .dropdown-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 66px;
  width: 100%;
  background: #fff;
  padding: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  
}

.dropdown .dropdown-content {
  top: 5.3rem;
  left: 1rem;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 200px;
  
}

.dropdown .dropdown-content .dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: all 0.3;
}

.dropdown .dropdown-content .dropdown-item:hover {
  background-color: #f4f4f4;
}

.nav-container .nav-item-user {
  color: #fff;
}

