.pre-payment-container {
    width: 900px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.pre-payment-container button ,.payment-success button {
    padding: 1.5rem 2rem;
    margin: 0rem 0.5rem;
    color: #fff;
    background: #0280a7;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: ease-in 0.5s ease;
    font-size: 30px;
}

.pre-payment-container button:hover , .payment-success button:hover{
    background: #015872;
}

.payment-success {
    width: 720px;
    height: 480px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    z-index: 998;
    border-radius: 10px;
}

.payment-success h1 {
    font-size: 40px;
    font-weight: 400;
    margin-bottom: 2rem;
}

.payment-container {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    position: relative;
}

.payment-content {
    width: 49%;
    height: 550px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 5rem ;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.payment-content h2 {
    margin: 1.5rem;
    font-size: 26px;
}

.payment-content h3 {
    margin: 1.5rem;
    font-size: 18px;
}

.payment-content button{
    margin: 0.4rem 0rem;
    width: 300px;
    height: 50px;
    border: none;
    font-size: 30px;
    padding: 0.5rem 1rem;
    border-radius: 14px;
    color: #ffffff;
    background: #0280a7;
    cursor: pointer;
}

.payment-content button:hover {
    background: #015872;
}

.payment-box {
    padding: 2rem 0rem;
    width: 49%;
    height: 550px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.payment-box .shortcut-box{
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment-box .shortcut {
    padding: 0rem 6rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 1rem;
    
}

.payment-box .shortcut .btn {
    width: 80px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0280a7;
    color: #fff;
    font-size: 26px;
    border-radius: 10px;
    cursor: pointer;
}

.payment-box .insert-box {
    display: flex;
    justify-content: center;
    align-items: start;
    width: 100%;
    height: 350px;
    margin: 1rem auto;
}

.payment-box .insert-box .num1 .num , .payment-box .insert-box .num2 .num ,.payment-box .insert-box .num3 .num {
    margin: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background: #0280a7;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
}

.payment-box .shortcut .btn:hover ,.payment-box .insert-box .num1 .num:hover , .payment-box .insert-box .num2 .num:hover , .payment-box .insert-box .num3 .num:hover {
    background: #015872;
}
