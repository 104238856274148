.nav {
    background: #353535;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.sidebar-nav .sidebar-wrap .nav-icon,
.nav .nav-icon {
    margin-left: 2rem;
    font-size: 2rem;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.sidebar-nav {
    background: #353535;
    height: 100vh;
    width: 210px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    transition: 350ms;
    z-index: 999;
}

.sidebar-nav .sidebar-wrap {
    width: 100%;
}

.sidebar-nav .sidebar-wrap .nav-icon {
    justify-content: flex-end;
    padding: 1rem;
}

.sidebar-nav .sidebar-user {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
}

.sidebar-nav .sidebar-user .user {
    display: flex;
    justify-content: start;
    align-items: center;
    background: #fff;
    border-radius: 20px;
    height: 60px;
}

.sidebar-nav .sidebar-user button {
    margin: 0.5rem;
    padding: 0.5rem;
    align-self: end;
    border: none;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    
}

.sidebar-nav .sidebar-user .user .user-img{
    margin: 0.3rem;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
}

.sidebar-nav .sidebar-user .user .user-img img {
    width: 100%;
    height: 100%;
}

.sidebar-nav .sidebar-user .user h3{
    text-align: center;
    width: 60%;
    font-size: 15px;
    font-weight: 600;
}

.sidebarLink {
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    font-size: 18px;
    text-decoration: none;
    
    &:hover {
        background: #202020;
        border-left: 8px solid #632ce4;
        cursor: pointer;
    }
}

.sidebarLink .sidebarLable{
    margin-left: 10px;
}

.dropdownLink {
    background: #353535;
    height: 40px;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #f5f5f5;
    font-size: 16px;

    &:hover {
        background: #202020;
        cursor: pointer;
    }
}