.box-bill {
    display: flex;
    justify-content: center;
    align-items: start;

}

.box-bill .bill-table-box {
    width: 1000px;
    padding: 1rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.box-bill .bill-table-box table {
    text-align: center;
    width: 100%;
    border-collapse: collapse;
}


.box-bill .bill-table-box table tr th{
    background: #04AA6D;
    color: white;
    padding: 1.5rem 0rem;
    border: none;
}

.box-bill .bill-table-box table tr #id{
    width: 100px;
}

.box-bill .bill-table-box table .item-colum {
    border-bottom: solid #222222 1px;
}

.box-bill .bill-table-box table tr:hover {
    background: #999999;
}


.box-full {
    margin: 1rem auto;
    display: flex;
    width: 790px;
    justify-content: center;
    align-content: start;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.bill-btn {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    
}

.bill-btn button {
    border-radius: 10px;
    margin: 0rem 0.5rem;
    padding: 0.7rem 2rem;
    font-size: 18px;
    border: none;
    background: rgb(18, 155, 75);
    color: #fff;

}

.billdata-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.billdata-box .bill-btn{
    display: flex;
    justify-content: center;
}

.billdata-box .bill-btn button{
    margin: 0rem 0.5rem;
    padding: 0.5rem 1.5rem;
    background: #12b161;
    font-size: 18px;
    color: white;
    border: none;
    border-radius: 8px;
}

.billdata-box .billdata-content {
    width: 350px;
    border: solid 1px #929292 ;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background: #ffff;
    padding: 1rem;
    margin: 1rem 0rem;
}

.billdata-box .billdata-content .header {
    width: 100%;
    text-align: center;
    margin: 1rem 0rem;
}

.billdata-box .billdata-content .header h2{
    font-weight: 700;
}

.billdata-box .billdata-content .sub-header{
    margin: 1rem 0rem;
    padding: 1rem 1rem;
    border-bottom: dashed 1px #222222;
}

.billdata-box .billdata-content .content{
    padding: 0rem 1rem;
    padding-bottom: 1rem;
    border-bottom: dashed 1px #222222;
}

.billdata-box .billdata-content .content #head{
    font-weight: 700;
}

.billdata-box .billdata-content .content .sub-footer-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.3rem 0rem;
}

.billdata-box .billdata-content .footer{
    padding: 1rem 1rem;
    padding-bottom: 1rem;
}

.billdata-box .billdata-content .footer .sub-footer-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.3rem 0rem;
}