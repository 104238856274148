.login-container {
    width: 900px;
    height: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container .login-box{
    width: 400px;
    height: 250px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container .login-box:hover {
    box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
}

.login-container .login-box .login-blog{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem 2.4rem;
}

.login-container .login-box .login-blog input {
    margin: 0.5rem 0rem;
    outline: none;
    padding: 0.7rem 1rem;
    border: none;
    background: #e4e4e4;
    font-size: 20px;
}

.login-container .login-box .login-blog button {
    margin-top: 0.5rem;
    outline: none;
    padding: 0.7rem 1rem;
    font-size: 20px;
    background: #15b35c;
    border: none;
    color: white;
    transition: all 0.6s ease;
}

.login-container .login-box .login-blog button:hover{
    background: #0f7a3f;
}